// @ts-nocheck
import React from 'react';
import { ApplyPluginsType, dynamic } from '/build/node_modules/umi/node_modules/@umijs/runtime';
import * as umiExports from './umiExports';
import { plugin } from './plugin';

export function getRoutes() {
  const routes = [
  {
    "path": "/",
    "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/build/src/pages/error-boundaries')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/build/src/pages/app-root')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/build/src/pages/theme-provider')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/build/src/pages/app-load')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/build/src/pages/auth-provider')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'/build/src/pages/tour-provider')})],
    "component": dynamic({ loader: () => import(/* webpackChunkName: 'layouts__CustomLayout' */'/build/src/layouts/CustomLayout')}),
    "routes": [
      {
        "path": "/viewers",
        "routes": [
          {
            "name": "tool",
            "path": "/viewers/tool/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__viewers__tool' */'/build/src/pages/viewers/tool')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          },
          {
            "name": "folder",
            "path": "/viewers/folder/:id",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__viewers__folder' */'/build/src/pages/viewers/folder')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          }
        ]
      },
      {
        "path": "/user",
        "routes": [
          {
            "path": "/user",
            "routes": [
              {
                "name": "login",
                "path": "/user/login",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__Login' */'/build/src/pages/user/Login')}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
                "exact": true
              },
              {
                "name": "profile",
                "path": "/user/profile",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__user__profile' */'/build/src/pages/user/profile')}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
                "exact": true
              }
            ]
          }
        ]
      },
      {
        "path": "/settings",
        "name": "settings",
        "tourId": "menu_settings",
        "icon": "setting",
        "routes": [
          {
            "path": "/settings/services",
            "name": "services",
            "tourId": "menu_services",
            "access": "canAdmin",
            "routes": [
              {
                "name": "KeyCloak",
                "access": "canAdmin",
                "path": "/settings/services/keycloak",
                "tourId": "menu_services_keycloak",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__services__keycloak' */'/build/src/pages/settings/services/keycloak')}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
                "exact": true
              },
              {
                "name": "Hasura",
                "access": "canAdmin",
                "path": "/settings/services/hasura",
                "tourId": "menu_services_hasura",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__services__hasura' */'/build/src/pages/settings/services/hasura')}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
                "exact": true
              },
              {
                "name": "Grafana",
                "access": "canAdmin",
                "path": "/settings/services/grafana",
                "tourId": "menu_services_grafana",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__services__grafana' */'/build/src/pages/settings/services/grafana')}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
                "exact": true
              },
              {
                "name": "JasperServer",
                "access": "canAdmin",
                "path": "/settings/services/jasperserver",
                "tourId": "menu_services_jasperserver",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__services__jasperserver' */'/build/src/pages/settings/services/jasperserver')}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
                "exact": true
              },
              {
                "name": "NextCloud",
                "access": "canAdmin",
                "path": "/settings/services/nextcloud",
                "tourId": "menu_services_nextcloud",
                "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__services__nextcloud' */'/build/src/pages/settings/services/nextcloud')}),
                "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
                "exact": true
              }
            ]
          },
          {
            "name": "application",
            "access": "canAdmin",
            "path": "/settings/application",
            "tourId": "menu_application",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__application' */'/build/src/pages/settings/application')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          },
          {
            "name": "tools",
            "access": "canAdmin",
            "path": "/settings/tools",
            "tourId": "menu_tools",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__tools' */'/build/src/pages/settings/tools')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          },
          {
            "name": "navigation",
            "access": "canAdmin",
            "path": "/settings/navigation",
            "tourId": "menu_navigation",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__navigation' */'/build/src/pages/settings/navigation')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          },
          {
            "name": "onboarding",
            "access": "canAdmin",
            "path": "/settings/onboarding",
            "tourId": "menu_onboarding",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__onboarding' */'/build/src/pages/settings/onboarding')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          },
          {
            "name": "users",
            "access": "canRealmAdmin",
            "path": "/settings/users",
            "tourId": "menu_users",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__users' */'/build/src/pages/settings/users')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithRealmAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          },
          {
            "name": "groups",
            "access": "canRealmAdmin",
            "path": "/settings/groups",
            "tourId": "menu_groups",
            "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__settings__groups' */'/build/src/pages/settings/groups')}),
            "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/lib/hocs/WithRealmAdminAccess')}), dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
            "exact": true
          }
        ]
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__home' */'/build/src/pages/home')}),
        "name": "main",
        "hideInMenu": true,
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
        "exact": true
      },
      {
        "component": dynamic({ loader: () => import(/* webpackChunkName: 'p__404' */'/build/src/pages/404')}),
        "wrappers": [dynamic({ loader: () => import(/* webpackChunkName: 'wrappers' */'@/pages/error-boundaries')})],
        "exact": true
      }
    ]
  }
];

  // allow user to extend routes
  plugin.applyPlugins({
    key: 'patchRoutes',
    type: ApplyPluginsType.event,
    args: { routes },
  });

  return routes;
}
