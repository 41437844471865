import { reducers } from "./reducers";

export const sharedValuesDefaultState = {
  range: undefined,
  updater: undefined,
  autoUpdateToken: undefined,
  manualUpdateToken: undefined
};

export default {
  namespace: "shared-values",
  state: sharedValuesDefaultState,
  reducers
};
