import { type SharedValuesStore } from "./types";

export const reducers = {
  update(state: SharedValuesStore, action: { payload: SharedValuesStore }) {
    return {
      ...state,
      ...action.payload
    };
  }
};
