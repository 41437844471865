import { authInterceptor, hasuraRoleInterceptor } from "./interceptors";
import { getEnvVars } from "@/lib/common/get-env-vars";
import axios, { type AxiosInstance } from "axios";

export enum EClient {
  Hasura = "hasura"
}

const getInstances = function (client?: EClient) {
  const { env } = getEnvVars();
  const restBaseUrl = `${env.APP_API_URL}/${env.API_V2}`;

  const cleanInstance = axios.create({ baseURL: restBaseUrl });
  const protectedInstance = axios.create({ baseURL: restBaseUrl });

  protectedInstance.interceptors.request.use(async (config) => {
    const controller = new AbortController();
    const token = await authInterceptor();

    if (!token) {
      controller.abort();
      window.location.pathname = "/user/login";
    }

    !config.headers && (config.headers = {});
    config.headers.Authorization = `Bearer ${token}`;

    if (client === EClient.Hasura) {
      hasuraRoleInterceptor(token, config);
    }

    return {
      ...config,
      signal: controller.signal
    };
  });

  return { cleanInstance, protectedInstance };
};

const instanceTypes = ["auth", "noauth"] as const;
type RestInstance = (typeof instanceTypes)[number];

const getRestInstance = (
  kind: RestInstance = "auth",
  client?: EClient
): AxiosInstance => {
  const { cleanInstance, protectedInstance } = getInstances(client);

  if (kind === "auth") return protectedInstance;
  if (kind === "noauth") return cleanInstance;
  return cleanInstance;
};

export { getRestInstance };
