import { refreshReq } from "./keycloak/user/raw-requests";
import { getTokenPayload } from "@/lib/common/jwt";
import { tokenStorage } from "@/lib/storage/token";

const authInterceptor = async () => {
  if (process.env.STORYBOOK) {
    return "mocked-token";
  }

  const { getTokens, setTokens } = tokenStorage();
  const { accessToken, refreshToken } = getTokens();

  if (getTokenPayload(accessToken)) return accessToken;
  if (!getTokenPayload(refreshToken)) {
    return null;
  }
  try {
    const { access_token, refresh_token } = await refreshReq({
      refreshToken: refreshToken!
    });

    setTokens({ accessToken: access_token, refreshToken: refresh_token });
    return access_token;
  } catch {
    return null;
  }
};

const hasuraRoleInterceptor = (
  token: string | null,
  config: Record<string, any>
) => {
  try {
    const hasuraRole = getTokenPayload(token)?.resource_access?.mes?.roles;
    if (hasuraRole?.includes("admin")) {
      config.headers["x-hasura-role"] = "admin";
    }
  } catch {
    /* empty */
  }
};

export { authInterceptor, hasuraRoleInterceptor };
