import {
  type ApplicationSettings,
  OnboardingSettings,
  type Settings
} from "./types";

export const reducers = {
  setOnboardingSettings(
    state: Settings,
    action: { payload: OnboardingSettings }
  ) {
    return {
      ...state,
      onboarding: [...action.payload]
    };
  },
  setApplicationSettings(
    state: Settings,
    action: { payload: ApplicationSettings }
  ) {
    return {
      ...state,
      application: {
        ...state.application,
        ...action.payload
      },
      loaded: {
        application: true
      }
    };
  },
  setRangeDefaultSettings(
    state: Settings,
    action: {
      payload: NonNullable<
        NonNullable<ApplicationSettings["time_functions"]>["range"]
      >["default"];
    }
  ) {
    return {
      ...state,
      application: {
        ...state.application,
        time_functions: {
          ...state.application.time_functions,
          range: {
            ...state.application.time_functions?.range,
            default: { ...action.payload }
          }
        }
      }
    };
  }
};
