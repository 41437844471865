const getEnvVars = () => {
  let env = {
    APP_API_URL: "",
    API_V2: process.env.API_V2,
    HASURA_GQL: process.env.HASURA_GQL,
    KEYCLOAK: process.env.KEYCLOAK,
    JASPER_SERVER: process.env.JASPER_SERVER,
    GRAFANA: process.env.GRAFANA,
    NEXTCLOUD: process.env.NEXTCLOUD
  };

  try {
    env = {
      ...env,
      // @ts-ignore
      ...(ENV || {})
    };
  } catch (e) {
    /* empty */
  }
  return { env };
};

export { getEnvVars };
