import fileManager from "./component/fileManager";
import { loggerViewerLocaleRu } from "@/components/tools/logger-viewer/locales";
import logger from "@/locales/ru-RU/component/logger";

export default {
  // Common
  "component.blank": "Пусто",
  "component.format-output-for": "Формат вывода для",
  "component.refresh": "Обновить",
  "component.file-already-attached":
    "Файл {label} уже прикреплен в этой строке",
  "component.file-not-found": "Файл не найден",
  "component.to-detach-file": "Открепить файл?",
  "component.to-attach": "Прикрепить",
  "component.rename": "Переименовать",
  "component.file-name": "Имя файла",
  "component.size": "Размер",
  "component.directories": "Директории",
  "component.path": "Путь",
  "component.enter-a-fractional-number": "Введите дробное число",
  "component.confirm-delete": "Вы уверены, что хотите удалить",
  "component.confirm-delete-with-filler":
    "Вы уверены, что хотите удалить {title}?",
  "component.to-block": "Заблокировать",
  "component.to-unblock": "Разблокировать",
  "component.read-only": "Только чтение",
  "component.are-you-sure-delete-this-panel":
    "Вы уверены в удалении этой панели?",
  "component.autoheight": "Автовысота",
  "component.click-action": "Действие по клику",
  "component.enable": "Доступно",
  "component.tagSelect.expand": "Expand",
  "component.tagSelect.collapse": "Collapse",
  "component.tagSelect.all": "Все",
  "component.logout": "Выход",
  "component.save": "Сохранить",
  "component.clear": "Очистить",
  "component.test": "Тест",
  "component.login": "Логин",
  "component.username": "Имя пользователя",
  "component.password": "Пароль",
  "component.create": "Создать",
  "component.sync": "Синхронизация",
  "component.delete": "Удалить",
  "component.move": "Переместить",
  "component.format": "Форматировать",
  "component.upload": "Загрузить",
  "component.download": "Скачать",
  "component.reset": "Сброс",
  "component.internalUrl": "Адрес (внутренний)",
  "component.externalUrl": "Адрес (внешний)",
  "component.finish": "Завершить",
  "component.enabled": "Включена",
  "component.disabled": "Выключена",
  "component.not-available": "Извините, данная страница вам недоступна",
  "component.home": "На Главную",
  "component.on": "Вкл",
  "component.off": "Выкл",
  "component.add": "Добавить",
  "component.edit": "Редактировать",
  "component.addition": "Добавление",
  "component.updating": "Обновление",
  "component.deleting": "Удаление",
  "component.renaming": "Переименование",
  "component.uploading": "Загрузка",
  "component.downloading": "Скачивание",
  "component.confirm-delete-tool-title": "Вы уверены, что хотите удалить?",
  "component.confirm-delete-menu-title": "Вы уверены, что хотите удалить меню ",
  "component.confirm-delete-menu-group":
    "Вы уверены, что хотите удалить группу ",
  "component.group-has-been-added": "Эта группа уже добавлена!",
  "component.confirm-create-menu-title": "Создать меню?",
  "component.yes": "Да",
  "component.no": "Нет",
  "component.cancel": "Отмена",
  "component.profile": "Профиль",
  "component.label.first-name": "Имя",
  "component.label.last-name": "Фамилия",
  "component.label.email": "E-mail",
  "component.placeholder.first-name": "Пример: Иван",
  "component.placeholder.last-name": "Пример: Иванов",
  "component.placeholder.email": "Пример: login@mail.ru",
  "component.name_filer": "Фильтр по имени",
  "component.type_filer": "Фильтр по типу",
  "component.all": "Все",
  "component.show_disabled": "Отображать недоступные",
  "component.preview": "Предпросмотр",
  "component.name": "Имя",
  "component.description": "Описание",
  "component.label": "Ярлык",
  "component.type": "Тип",
  "component.menu": "Меню",
  "component.folder": "Папка",
  "component.title.settings": "Настройки",
  "component.title.add_tool": "Добавить инструмент",
  "component.theme": "Тема",
  "component.current": "Текущая",
  "component.light": "Светлая",
  "component.medium": "Средняя",
  "component.dark": "Тёмная",
  "component.full": "Полный",
  "component.kiosk": "Киоск",
  "component.kiosk_tv": "Киоск ТВ",
  "component.view": "Вид",
  "component.client": "Клиент",
  "component.client_tooltip": "Клиенские настройки",
  "component.server": "Сервер",
  "component.server_tooltip": "Серверные настройки",
  "component.loading": "Загрузка...",
  "component.version": "Версия",
  "component.default": "По умолчанию",
  "component.title.confirm.share.enabled":
    "Are you sure set property to enable?",
  "component.title.confirm.share.disabled":
    "Are you sure set property to disable?",
  "component.error.tool-unsupported": "Инструмент не поддерживается",
  "component.example": "Пример",
  "component.error": "Ошибка",
  "component.endpoint": "Конечная точка",
  "component.report": "Отчет",
  "component.dashboard": "Дашборд",
  "component.panel": "Панель",
  "component.panel_on_click": "Панель onClick",
  "component.constructor": "Конструктор",
  "component.variables": "Переменные",
  "component.fill": "Заполнить",
  "component.range": "Диапазон",
  "component.select_range": "Выбор диапазона",
  "component.range_left_border": "диапазон.левая граница",
  "component.range_right_border": "диапазон.правая граница",
  "component.visible": "Видимый",
  "component.use_global_property": "Использовать глобальное свойство",
  "component.default_value": "Значение по умолчанию",
  "component.presets": "Предустановки",
  "component.templates": "Шаблоны",
  "component.functions": "Функции",
  "component.Last_5_minutes": "Последние 5 минут",
  "component.Last_15_minutes": "Последние 15 минут",
  "component.Last_30_minutes": "Последние 30 минут",
  "component.Last_1_hour": "Последний час",
  "component.Last_3_hours": "Последние 3 часа",
  "component.Last_6_hours": "Последние 6 часов",
  "component.Last_12_hours": "Последние 12 часов",
  "component.Last_24_hours": "Последние 24 часа",
  "component.Last_2_days": "Последние 2 дня",
  "component.Last_7_days": "Последние 7 дней",
  "component.Last_30_days": "Последние 30 дней",
  "component.Last_90_days": "Последние 90 дней",
  "component.Last_6_months": "Последние 6 месяцев",
  "component.Last_1_year": "Последний год",
  "component.Last_2_years": "Последние 2 года",
  "component.Last_5_years": "Последние 5 лет",
  "component.Current_day": "Текущий день",
  "component.Current_week": "Текущая неделя",
  "component.Current_month": "Текущий месяц",
  "component.Current_quarter": "Текущий квартал",
  "component.Current_year": "Текущий год",
  "component.Yesterday": "Вчера",
  "component.Last_week": "Прошлая неделя",
  "component.Last_month": "Прошлый месяц",
  "component.Last_quarter": "Прошлый квартал",
  "component.Last_year": "Прошлый год",
  "component.event": "Событие",
  "component.events": "События",
  "component.onClick": "Клик",
  "component.action": "Действие",
  "component.sort": "Сортировка",
  "component.service_unavailable": "Сервис недоступен",
  "component.show": "Показать",
  "component.hide": "Скрыть",
  "component.attributes": "Атрибуты",
  "component.actions": "Действия",
  "component.state": "Состояние",
  "component.multiple_values": "Несколько значений",
  "component.include": "Включить",
  "component.select_all": "Выбрать все",
  "component.values_separated_by_comma": "Значения разделенные запятой",
  "component.decorate": "Оформление",
  "component.logo": "Логотип",
  "component.russian": "Русский",
  "component.english": "Английский",
  "component.kazakh": "Казахский",
  "component.deutsch": "Немецкий",
  "component.style": "Стиль",
  "component.navigation": "Навигация",
  "component.top": "Сверху",
  "component.left": "Слева",
  "component.mix": "Смешанная",
  "component.visibility": "Видимость",
  "component.select-range": "Выбор диапазона",
  "component.absolute": "Абсолютное",
  "component.relative": "Относительное",
  "component.token": "Токен",
  "component.from": "из",
  "component.change-value": "Изменить значение",
  "component.applicationName": "Имя приложения",
  "component.source": "Источник",
  "component.values": "Значения",
  "component.multi-values": "Несколько значений",
  "component.include-select-all": "Включить [Все]",
  "component.query": "Запрос",
  "component.custom": "Пользовательский",
  "component.value": "Значение",
  "component.constant": "Константа",
  "component.link": "Связная",
  "component.type-value": "Тип переменной",
  "component.input-variable-name": "Задайте имя переменной!",
  "component.input-rule-name": "Задайте имя правила!",
  "component.name-already-exists": "Запись с таким именем уже существует!",
  "component.date-output-format": "Формат вывода",
  "component.date-output-accuracy": "Точность ввода",
  "component.second": "секунда",
  "component.minute": "минута",
  "component.hour": "час",
  "component.day": "день",
  "component.week": "неделя",
  "component.month": "месяц",
  "component.quarter": "квартал",
  "component.year": "год",
  "component.invalid-variable-value": "Не верное значение переменной",
  "component.change": "Изменить",
  "component.unpin": "Открепить",
  "component.removed": "Удален",
  "component.not-removed": "Не удален",
  "component.added": "Добавлен",
  "component.not-added": "Не добавлен",
  "component.changed": "Изменен",
  "component.not-changed": "Не изменен",
  "component.user-created": "пользователь, который добавил",
  "component.user-updated": "пользователь, который обновил",
  "component.date-updated": "дата обновления",
  "component.date-created": "дата добавления",
  "component.conditional-formatting": "Условное форматирование",
  "component.rule": "Правило",
  "component.cell": "Ячейки",
  "component.please-select": "Выберите из списка",
  "component.formatter": "Форматер",
  "component.font-color": "Цвет шрифта",
  "component.background-color": "Цвет фона",
  "component.font": "Шрифт",
  "component.font-size": "Размер шрифта",
  "component.condition": "Условие",
  "component.localization.support": "Поддержка локализации",
  "component.not-path-for-panel": "Не указан путь для панели",
  "component.access": "Доступ",
  "component.info": "Инфо",
  "component.system-info": "Системная информация",
  "component.width": "Ширина",
  "component.empty": "пустое",
  "component.file": "Файл",
  "component.not-found": "не найден",
  "component.clone": "Клонировать",
  "component.row-number": "Номер строки",
  "component.invalid-value": "Введено неверное значение",

  // Grafana
  "component.grafana.internal": "Адрес сервера(внутренний)",
  "component.grafana.external": "Внешняя ссылка",
  "component.grafana.basic-api-uri": "Basic api uri",
  "component.grafana.api-key": "Api key",
  "component.footer.allRightReserved": "Все права защищены",
  "component.roles": "Роли",
  "component.groups": "Группы",
  "component.tools": "Инструменты",
  "component.grafana.remove-panel": "Удалить панель",
  "component.grafana.columns-count": "Столбцы",
  "component.grafana.row-height": "Высота строки",
  "component.grafana.user-transformation": "Изменять панели во вьювере",
  "component.grafana.stretch-to-window-height": "Растянуть на высоту окна",
  "component.grafana.edit-panel": "Редактировать панель",
  "component.grafana.panel-group": "Группа панелей",
  "component.grafana.use-board": "Использовать доску",
  "component.grafana.system-info-error":
    "Не удалось получить информацию по сервису",
  "component.grafana.add-panel": "Добавить",

  // System db
  "component.system-db.hostname": "хост",
  "component.system-db.port": "порт",
  "component.system-db.name": "имя базы данных",
  "component.system-db.username": "имя пользователя",
  "component.system-db.driver": "драйвер",
  "component.system-db.want-to-delete":
    "Эта операция необратима. Вы уверены, что хотите удалить системную базу данных?",
  "component.system-db.want-to-format":
    "Эта операция необратима. Вы уверены, что хотите форматировать системную базу данных?",

  // Sso
  "component.sso.realm": "Область",
  "component.sso.clientId": "ID клиента",
  "component.sso.port": "Порт",
  "component.sso.secret": "Секрет",
  "component.sso.system-info-error":
    "Не удалось получить информацию по сервису",

  //Rs
  "component.rs.system-info-error": "Не удалось получить информацию по сервису",

  // User-profile
  "component.profile.login-name": "Имя входа",
  "component.profile.new-password": "Новый пароль",
  "component.profile.new-password-repeat": "Новый пароль [повтор]",
  "component.profile.change-password": "Сменить пароль",

  // Hasura
  "component.hasura.service-address": "Адрес сервиса",
  "component.hasura.server": "Сервер",
  "component.hasura.server-settings": "Настройки сервера",
  "component.hasura.select.label": "Таблица",
  "component.hasura.select.placeholder": "Выберите таблицу",
  "component.hasura.fields": "Поля",
  "component.hasura.table.label": "Ярлык",
  "component.hasura.table.type": "Тип",
  "component.hasura.table.attributes": "Атрибуты",
  "component.hasura.table.action": "Действие",
  "component.hasura.field": "Поле",
  "component.hasura.no-description": "Нет описания",
  "component.hasura.fields-not-configured": "Не настроены поля",
  "component.hasura.form-constructor": "Конструктор формы ввода/вывода",
  "component.hasura.min-height": "Мин. высота",
  "component.hasura.min-width": "Мин. ширина",
  "component.hasura.max-height": "Макс. высота",
  "component.hasura.max-width": "Макс. ширина",
  "component.hasura.columns-quantity": "Кол-во столбцов",
  "component.hasura.missing-primary-key": "Отсутствует первичный ключ",
  "component.hasura.table-settings": "Настройки таблицы",
  "component.hasura.decorate": "Оформление",
  "component.hasura.fitAndFill": "Строки на всю ширину таблицы",
  "component.hasura.rowHeight": "Высота строки",
  "component.hasura.fix": "Фиксатор",
  "component.hasura.rowsTop": "Сверху",
  "component.hasura.colsLeft": "Слева",
  "component.hasura.colsRight": "Справа",
  "component.hasura.export": "Экспорт",
  "component.hasura.import": "Импорт",
  "component.hasura.output": "Вывод",
  "component.hasura.relation": "Отношение",
  "component.hasura.restrictions": "Ограничения",
  "component.hasura.content-type": "Тип контента",
  "component.hasura.no-value": "Нет значения",
  "component.hasura.source": "Источник",
  "component.hasura.matrix": "Матрица",
  "component.hasura.axisY": "Ось Y",
  "component.hasura.axisX": "Ось X",
  "component.hasura.matrix-field": "Поле матрицы",
  "component.hasura.nesting-level": "Уровень вложенности",
  "component.hasura.pagination": "Пагинация",
  "component.hasura.rows-per-page": "строк/стр",
  "component.hasura.conditions": "Условия",
  "component.hasura.sorting-type": "Тип сортировки",
  "component.hasura.soft": "Мягкое",
  "component.hasura.invalid-json-format": "Невалидный json-формат",
  "component.hasura.invalid-xml-format": "Невалидный xml-формат",
  "component.hasura.adding-group": "Добавление группы",
  "component.hasura.changing-group": "Изменение группы",
  "component.hasura.permissions": "Права",

  // Global
  "component.global.name-restriction": "Текст не более 50 символов",
  "component.global.desc-restriction": "Текст не более 255 символов",
  "component.global.language-selection": "Язык",
  "component.global.default-value": "Значение по умолчанию",
  "component.global.languages": "Список",

  // AuthTools
  "component.auth.users": "Пользователи",
  "component.auth.disabled-users": "Заблокированные пользователи ",
  "component.auth.groups": "Группы",
  "component.auth.disabled-tools": "Заблокированные инструменты",
  "component.auth.disabled": "Недоступен",
  "component.auth.enabled": "Доступен",
  "component.auth.inherited": "Унаследовано",
  "component.auth.default": "По умолчанию",
  "component.auth.name-filter": "Фильтр по именам",
  "component.auth.tools-filter": "Фильтр по инструментам",

  // Navigation
  "component.navigation.change-directory": "Изменить директорию",
  "component.navigation.change-tool": "Изменить инструмент",

  // Crud
  "component.function.pagination-top": "Верх",
  "component.function.pagination-bottom": "Низ",
  "component.ui.CrudFormConstructor.tooltipColumnsQuantity":
    "Сначала освободите занятое элементами место, а потом уменьшайте значение",

  // report
  "component.report.path-not-set": "Не задан путь для отчета!",
  "component.report.time-functions": "Функции времени",

  // DataInput
  "component.data-input.choose": "выбрать",
  "component.data-input.download": "Скачать",
  "component.data-input.upload": "Загрузить",
  "component.data-input.new-file": "Новый файл",
  "component.data-input.date-of-download": "дата загрузки",
  "component.data-input.name-of-uploaded-file": "Имя загруженного файла",
  "component.data-input.no-file-selected": "Не выбран файл",
  "component.data-input.delete-the-selected-file": "Удалить выбранный файл?",
  "component.data-input.enter-file-name": "Введите название файла",

  // DataInputs placeholders
  "component.data.input.int": "Пример: 123",
  "component.data.input.bigint": "Пример: 123",
  "component.data.input.string": "Пример: Символы",
  "component.data.input.xml": "Пример: Символы",
  "component.data.input.float8": "Пример: 123.321",
  "component.data.input._float8": "Пример: 123.321",
  "component.data.input._int4": "Пример: 123",
  "component.data.input.timestamp": "Выберите дату и время",
  "component.data.input.timestamptz": "Выберите дату и время",
  "component.data.input.date": "Выберите дату",
  "component.data.input.bpchar": "Введите текст",
  "component.data.input.object": "Выберите из списка",
  "component.data.input.time": "Выберите время",
  "component.data.input._varchar": "Пример: абв",
  "component.data.input.uuid": "Введите uuid",
  "component.data.input.json":
    'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  "component.data.input.jsonb":
    'Пример: { "fileName": "my file name", "fileSize": 1024 }',
  "component.data.input.file-selection": "Выбор файла",

  // ArrayEditor
  "component.array-editor.delete-the-selected-row":
    "Вы уверены в удалении этой строки?",

  // IOCoordinates
  "component.io-coordinates.map": "Карта",

  // IOJsonbSys
  "component.io-jsonb-sys.history": "История",
  "component.io-jsonb-sys.user": "Пользователь",
  "component.io-jsonb-sys.timestamp": "Метка времени",
  "component.io-jsonb-sys.adding": "Добавление",
  "component.io-jsonb-sys.editing": "Редактирование",
  "component.io-jsonb-sys.deleting": "Удаление",

  // ActionButton
  "action.button-create": "Добавить",
  "action.button-save": "Сохранить",
  "action.button-cancel": "Отмена",
  "action.button-delete": "Удалить",
  "action.button-clone": "Клонировать",
  "action.button-import": "Импорт",
  "action.button-export": "Экспорт",
  "action.button-download": "Скачать",
  "action.button-upload": "Загрузить",
  "action.button-reset": "Сбросить",
  "action.button-play": "Запуск",
  "action.button-login": "Вход",
  "action.button-fill": "Заполнить",
  "action.button-update": "Обновить",
  "action.button-edit": "Редактировать",
  "action.button-remove": "Убрать",

  // FieldFilter
  "modal.field-filter-modal.filter": "Фильтр",
  "component.field-filter.filter": "Фильтр",
  "component.field-filter.type": "Тип",
  "component.field-filter.off": "Выключено",
  "component.field-filter.eq": "Равно",
  "component.field-filter.neq": "Не равно",
  "component.field-filter.gt": "Больше, чем",
  "component.field-filter.gte": "Больше или равно",
  "component.field-filter.in": "Входит в список",
  "component.field-filter.nin": "Не входит в список",
  "component.field-filter.is_null": "Пусто или отсутствует",
  "component.field-filter.lt": "Меньше, чем",
  "component.field-filter.lte": "Меньше или равно",
  "component.field-filter.like": "Подобно (по содержанию, с учетом регистра)",
  "component.field-filter.nlike":
    "Не подобно (по содержанию, с учетом регистра)",
  "component.field-filter.ilike": "Подобно (по содержанию, без учета регистра)",
  "component.field-filter.nilike":
    "Не подобно (по содержанию, без учета регистра)",
  "component.field-filter.similar": "Подобно (по сходству)",
  "component.field-filter.nsimilar": "Не подобно (по сходству)",
  "component.field-filter.regex": "Регулярное выражение (с учетом регистра)",
  "component.field-filter.nregex":
    "Не регулярное выражение (с учетом регистра)",
  "component.field-filter.iregex": "Регулярное выражение (без учета регистра)",
  "component.field-filter.niregex":
    "Не регулярное выражение (без учета регистра)",
  "component.field-filter.on": "Включено",
  "component.field-filter.value": "Значение",

  // editor/application
  "page.settings-application.general": "Общие",
  "page.settings-application.text": "Текст",
  "page.settings-application.logoMesone": "Логотип MESone",
  "page.settings-application.footer": "Футер",

  // User/profile
  "page.user-profile.user-name": "Имя пользователя",
  "page.user-profile.admin": "Администратор",
  "page.user-profile.first-name": "Имя",
  "page.user-profile.placeholder-name": "Иван",
  "page.user-profile.last-name": "Фамилия",
  "page.user-profile.default-tool": "Инструмент по умолчанию",
  "page.user-profile.tools": "Инструменты",
  "page.user-profile.placeholder-input-search": "Введите текст для поиска",
  "page.user-profile.groups": "Группы",

  // Instance editor
  "component.instance-editor.image": "Изображение",
  "component.instance-editor.text": "Текст",
  "component.instance-editor.main": "Основной",
  "component.instance-editor.additional": "Дополнительный",

  // Switcher
  "component.switcher.enabled": "Включено",

  // Select users groups
  "select-users-groups.users": "Пользователи",

  // Tools navigator
  "component.tools-navigator.showTypes": "Отображать типы",
  "component.tools-navigator.showBlocked": "Отображать недоступные",

  // Tool settings common
  "component.tool-settings-common.permissions": "Разрешения",
  "component.tool-settings-common.name": "Имя",
  "component.tool-settings-common.type": "Тип",
  "component.tool-settings-common.label": "Ярлык",
  "component.tool-settings-common.description": "Описание",
  "component.tool-settings-common.info": "Инфо",
  "component.tool-settings-common.sureDelete":
    "Вы уверены в удалении инструмента {name}?",

  // Table settings
  "component.table-settings.pagination": "Пагинация",
  "component.table-settings.rows": "строк/стр",
  "component.table-settings.top": "Сверху",
  "component.table-settings.bottom": "Снизу",
  "component.table-settings.fillAndFit": "Растянуть столбцы на всю ширину",
  "component.table-settings.rowHeight": "Высота строки",
  "component.table-settings.fontSize": "Размер шрифта",
  "component.table-settings.fix": "Фиксировать",
  "component.table-settings.left": "Слева",
  "component.table-settings.right": "Справа",
  "component.table-settings.export": "Экспорт",
  "component.table-settings.import": "Импорт",

  // Range editor
  "component.range-editor.range": "Диапазон",
  "component.range-editor.visibility": "Видимость",
  "component.range-editor.useGlobalProperty":
    "Использовать глобальное значение",
  "component.range-editor.presets": "Пресеты",
  "component.range-editor.defaultValue": "Значение по умолчанию",
  "component.range-editor.absolute": "Абсолютное",
  "component.range-editor.relative": "Относительное",

  // Range viewer
  "component.range-preset.Last_5_minutes": "посл. 5 мин.",
  "component.range-preset.Last_15_minutes": "посл. 15 мин.",
  "component.range-preset.Last_30_minutes": "посл. пол. часа",
  "component.range-preset.Last_1_hour": "посл. час",
  "component.range-preset.Last_3_hours": "посл. 3 ч.",
  "component.range-preset.Last_6_hours": "посл. 6 ч.",
  "component.range-preset.Last_12_hours": "посл. пол. дня",
  "component.range-preset.Last_24_hours": "посл. день",
  "component.range-preset.Last_2_days": "посл. 2 д.",
  "component.range-preset.Last_7_days": "посл. неделя",
  "component.range-preset.Last_30_days": "посл. месяц",
  "component.range-preset.Last_90_days": "посл. квартал",
  "component.range-preset.Last_6_months": "посл. пол. года",
  "component.range-preset.Last_1_year": "посл. год",
  "component.range-preset.Last_2_years": "посл. 2 г.",
  "component.range-preset.Last_5_years": "посл. 5 л.",
  "component.range-preset.Current_day": "тек. день",
  "component.range-preset.Current_week": "тек. неделя",
  "component.range-preset.Current_month": "тек. месяц",
  "component.range-preset.Current_quarter": "тек. квартал",
  "component.range-preset.Current_year": "тек. год",
  "component.range-preset.Yesterday": "вчера",
  "component.range-preset.Last_week": "прошлая неделя",
  "component.range-preset.Last_month": "прошлый месяц",
  "component.range-preset.Last_quarter": "прошлый квартал",
  "component.range-preset.Last_year": "прошлый год",

  // Updater editor
  "component.updater-editor.range": "Обновление",
  "component.updater-editor.visibility": "Видимость",
  "component.updater-editor.useGlobalValue": "Использовать глобальное значение",
  "component.updater-editor.defaultValue": "Значение по умолчанию",

  "component.updater-preset.Off": "Выкл.",
  "component.updater-preset.5s": "5сек.",
  "component.updater-preset.10s": "10сек.",
  "component.updater-preset.30s": "30сек.",
  "component.updater-preset.1m": "1мин.",
  "component.updater-preset.5m": "5мин.",
  "component.updater-preset.15m": "15мин.",
  "component.updater-preset.30m": "30мин.",
  "component.updater-preset.1h": "1ч.",
  "component.updater-preset.2h": "2ч.",
  "component.updater-preset.1d": "1д.",

  // List extended
  "component.list-extended.selectAll": "Выбрать все",
  "component.list-extended.all": "Все",

  // Data input int
  "component.data-input-int.placeholder": "Введите целое число",

  // Data input settings
  "component.data-input-settings.contentType": "Тип контента",
  "component.data-input-settings.systemInformation": "Системная информация",
  "component.data-input-settings.dateInputFormat": "Формат ввода даты",
  "component.data-input-settings.timeInputFormat": "Формат ввода времени",
  "component.data-input-settings.dateTimeInputFormat":
    "Формат ввода даты и времени",
  "component.data-input-settings.editor": "Редактор",
  "component.data-input-settings.files": "Файлы",
  "component.data-input-settings.coordinates": "Координаты",
  "component.data-input-settings.week": "Неделя",
  "component.data-input-settings.month": "Месяц",
  "component.data-input-settings.quarter": "Квартал",
  "component.data-input-settings.year": "Год",
  "component.data-input-jpg-base64.upload": "Загрузить",

  // Variable custom editor
  "component.variable.custom.editor": "Набор значений",
  "component.variable-custom-editor.preview-default-value":
    "Предпросмотр/Значение по умолчанию",

  // Variable value editor
  "component.variable-value-editor.dataType": "Тип данных",
  "component.variable-value-editor.boolean": "Логический",
  "component.variable-value-editor.int": "Целочисленный",
  "component.variable-value-editor.float4": "Вещественный",
  "component.variable-value-editor.float": "Вещественный",
  "component.variable-value-editor.bpchar": "Строковый",
  "component.variable-value-editor.date": "Дата",
  "component.variable-value-editor.time": "Время",
  "component.variable-value-editor.timestamp": "Метка времени",
  "component.variable-value-editor.bool": "Логический массив",
  "component.variable-value-editor.int4": "Целочисленный массив",
  "component.variable-value-editor.float8": "Вещественный массив",
  "component.variable-value-editor.varchar": "Строковый массив",
  "component.variable-value-editor.uuid": "Идентификатор",

  // Variable query editor
  "component.list-extended.templates": "Шаблоны",
  "component.list-extended.basic": "Базовый",
  "component.list-extended.textValueAliases": "Псевдонимы text/value",
  "component.list-extended.variables": "Переменные",
  "component.list-extended.query": "Запрос",
  "component.list-extended.previewDefaultValue":
    "Предпросмотр/Значение по умолчанию",

  // Row control
  "component.row-control.edit": "Изменить",
  "component.row-control.move": "Переместить",
  "component.row-control.delete": "Удалить",

  // Row mover
  "component.row-mover.move": "Переместить",
  "component.row-mover.rowNumber": "Номер строки",

  // Variables editor
  "component.variables-editor.name": "Имя",
  "component.variables-editor.label": "Ярлык",
  "component.variables-editor.type": "Тип",
  "component.variables-editor.attributes": "Атрибуты",
  "component.variables-editor.actions": "Действия",
  "component.variables-editor.confirmDelete":
    "Вы уверенны, что хотите удалить {name} переменную?",
  "component.variables-editor.var-value": "Переменная",
  "component.variables-editor.var-custom": "Статический список",
  "component.variables-editor.var-query": "Динамический список",

  // Inputs Control Editor
  "component.inputs-control-editor.dataTransfer": "Передача данных",
  "component.inputs-control-editor.Auto": "Авто",
  "component.inputs-control-editor.Manual": "Ручное",

  // Variable editor
  "component.variable-editor.add": "Добавить",
  "component.variable-editor.edit": "Изменить",
  "component.variable-editor.readOnly": "Только чтение",
  "component.variable-editor.visible": "Видимый",
  "component.variable-editor.name": "Имя",
  "component.variable-editor.enterVariableName": "Введите имя переменной",
  "component.variable-editor.label": "Ярлык",
  "component.variable-editor.description": "Описание",
  "component.variable-editor.type": "Тип",
  "component.variable-editor.width": "Ширина",
  "component.variable-editor.invalidName": "Недопустимое имя",

  // Table fields
  "component.table-fields.name": "Имя",
  "component.table-fields.label": "Ярлык",
  "component.table-fields.type": "Тип",
  "component.table-fields.attributes": "Атрибуты",
  "component.table-fields.actions": "Действия",
  "component.table-fields.totalItems": "Всего {i} элементов",

  // Table form editor
  "component.table-form-editor.preview": "Предпросмотр",
  "component.table-form-editor.min": "Мин.",
  "component.table-form-editor.max": "Макс.",
  "component.table-form-editor.minHeight": "Мин. высота",
  "component.table-form-editor.maxHeight": "Макс. высота",
  "component.table-form-editor.width": "Ширина",
  "component.table-form-editor.minWidth": "Мин. ширина",
  "component.table-form-editor.maxWidth": "Макс. ширина",
  "component.table-form-editor.columns": "Столбцов",
  "component.table-form-editor.minimumReached":
    "Достигнут минимум. Сначала освободите занимаемое место, а потом измените количество столбцов.",

  // Field sorting editor
  "modal.field-sorting-editor.edit": "Изменить",
  "modal.field-sorting-editor.field": "Поле",
  "modal.field-sorting-editor.sortingRule": "Правило сортировки",
  "modal.field-sorting-editor.noSorting": "Без сортировки",
  "modal.field-sorting-editor.asc": "По возрастанию",
  "modal.field-sorting-editor.asc_nulls_first": "По возрастанию null в начале",
  "modal.field-sorting-editor.asc_nulls_last": "По возрастанию null в конце",
  "modal.field-sorting-editor.desc": "По убыванию",
  "modal.field-sorting-editor.desc_nulls_first": "По убыванию null в начале",
  "modal.field-sorting-editor.desc_nulls_last": "По убыванию null в конце",

  // Conditional formatting
  "component.conditional-formatting.confirmDelete":
    "Вы уверенны, что хотите удалить {name} правило?",
  "component.conditional-formatting.name": "Имя",
  "component.conditional-formatting.description": "Описание",
  "component.conditional-formatting.attributes": "Атрибуты",
  "component.conditional-formatting.actions": "Действия",
  "component.conditional-formatting.totalItems": "Всего {i} элементов",
  "component.conditional-formatting.layer": "Слой",

  // Conditional Formatting Editor
  "component.conditional-format-editor.add": "Добавить",
  "component.conditional-format-editor.edit": "Изменить",
  "component.conditional-format-editor.name": "Имя",
  "component.conditional-format-editor.enterRuleName": "Введите имя правила",
  "component.conditional-format-editor.description": "Описание",
  "component.conditional-format-editor.fields": "Поля",
  "component.conditional-format-editor.allFields": "Все поля (строка целиком)",
  "component.conditional-format-editor.formatter": "Форматер",
  "component.conditional-format-editor.fontColor": "Цвет шрифта",
  "component.conditional-format-editor.backgroundColor": "Цвет фона",
  "component.conditional-format-editor.font": "Шрифт",
  "component.conditional-format-editor.bold": "Жирный",
  "component.conditional-format-editor.italic": "Курсив",
  "component.conditional-format-editor.underline": "Подчеркнутый",
  "component.conditional-format-editor.conditions": "Условия",

  // Table source
  "component.table-source.table": "Таблица",
  "component.table-source.selectTable": "Выберите таблицу",
  "component.table-source.description": "Описание",
  "component.table-source.options": "Вариант",
  "component.table-source.main": "Основная",
  "component.table-source.aggregated": "Агрегированная",
  "component.table-source.withKeys": "С ключами",

  // Tools editor
  "component.tools-editor.source": "Источник",
  "component.tools-editor.permissions": "Права",
  "component.tools-editor.fields": "Поля",
  "component.tools-editor.groups": "Группы",
  "component.tools-editor.tableSettings": "Настройки таблицы",
  "component.tools-editor.preview": "Предпросмотр",
  "component.tools-editor.formBuilder": "Конструктор формы ввода/вывода",
  "component.tools-editor.timeFunctions": "Функции времени",
  "component.tools-editor.variables": "Переменные",
  "component.tools-editor.conditions": "Условия",
  "component.tools-editor.sorting": "Сортировка",
  "component.tools-editor.conditionalFormatting": "Условное форматирование",
  "component.tools-editor.constructor": "Конструктор",
  "component.tools-editor.matrix": "Матрица",
  "component.tools-editor.additionalFormats": "Дополнительные форматы",
  "component.tools-editor.localizationSupport": "Поддержка локализации",
  "component.tools-editor.args": "Аргументы",
  "component.tools-editor.dataTransfer": "Передача данных в инструмент",

  // Table permissions
  "component.table-permissions.add": "Добавить",
  "component.table-permissions.update": "Обновить",
  "component.table-permissions.delete": "Удалить",

  // Dashboard editor
  "component.dashboard-editor.add": "Добавить",
  "component.dashboard-editor.edit": "Изменить",
  "component.dashboard-editor.name": "Имя",
  "component.dashboard-editor.selectPanelName": "Выберите имя панели",
  "component.dashboard-editor.localizationSupport": "Поддержка локализации",
  "component.dashboard-editor.view": "Вид",
  "component.dashboard-editor.full": "Полный",
  "component.dashboard-editor.kiosk": "Киоск",
  "component.dashboard-editor.kioskTV": "Киоск ТВ",
  "component.dashboard-editor.theme": "Тема",
  "component.dashboard-editor.current": "Текущая",
  "component.dashboard-editor.light": "Светлая",
  "component.dashboard-editor.dark": "Темная",
  "component.dashboard-editor.openToolOnClick": "Открыть инструмент по клику",
  "component.dashboard-editor.selectTool": "Выберите инструмент",

  // Dashboard designer
  "component.dashboard-designer.autoHeight": "Авто высота",
  "component.dashboard-designer.changePanelsInViewMode":
    "Изменять панели в режиме просмотра",
  "component.dashboard-designer.rowHeight": "Высота строки",
  "component.dashboard-designer.columns": "Столбцов",

  // Dashboard panel
  "component.dashboard-panel.edit": "Изменить",
  "component.dashboard-panel.delete": "Удалить",
  "component.dashboard-panel.confirmationMessage":
    "Вы уверенны, что хотите удалить {i} панель?",

  // Settings users
  "pages.settings-users.staticInformation": "Статическая информация",
  "pages.settings-users.created": "Создан",
  "pages.settings-users.userName": "Имя пользователя",
  "pages.settings-users.editableInformation": "Редактируемая информация",
  "pages.settings-users.name": "Имя",
  "pages.settings-users.surname": "Фамилия",
  "pages.settings-users.changePassword": "Изменить пароль",
  "pages.settings-users.newPassword": "Новый пароль",
  "pages.settings-users.enterNewPasswordAgain": "Повторно введите новый пароль",
  "pages.settings-users.groups": "Группы",
  "pages.settings-users.tools": "Инструменты",
  "pages.settings-users.confirmDelete":
    "Вы уверены, что хотите удалить {i} пользователя?",

  // Settings groups
  "pages.settings-groups.confirmDelete":
    "Вы уверенны, что хотите удалить {i} группу?",
  "pages.settings-groups.users": "Пользователи",
  "pages.settings-groups.subGroup": "Подгруппа",

  // Settings services grafana
  "pages.settings-grafana-services.version": "Версия",
  "pages.settings-grafana-services.executionTime": "Время выполнения",
  "pages.settings-grafana-services.description": "Описание",
  "pages.settings-grafana-services.error": "Ошибка",
  "pages.settings-grafana-services.waiting": "Ожидание",

  // Field editor simple
  "component.field-editor-simple.edit": "Изменить",
  "component.field-editor-simple.name": "Имя",
  "component.field-editor-simple.type": "Тип",
  "component.field-editor-simple.description": "Описание",
  "component.field-editor-simple.label": "Ярлык",
  "component.field-editor-simple.parameters": "Параметры",
  "component.field-editor-simple.keyField": "Ключевое поле",
  "component.field-editor-simple.readOnly": "Только чтение",
  "component.field-editor-simple.lazyLoading": "Ленивая загрузка",
  "component.field-editor-simple.quickView": "Быстрый просмотр",
  "component.field-editor-simple.quickEdit": "Быстрое редактирование",
  "component.field-editor-simple.sorting": "Сортировка",
  "component.field-editor-simple.filter": "Фильтр",
  "component.field-editor-simple.foreignKey": "Внешний ключ",
  "component.field-editor-simple.selectForeignKey": "Выберите внешний ключ",
  "component.field-editor-simple.formBuilder":
    "Конструктор формы быстрого просмотра / редактирования",
  "component.field-editor-simple.inputOutputSettings": "Настройки ввода/вывода",
  "component.field-editor-simple.outputFormat": "Формат вывода",
  "component.field-editor-simple.quickAction": "Быстрое действие",
  "component.field-editor-simple.disabled": "Отключено",
  "component.field-editor-simple.view": "Просмотр",
  "component.field-editor-simple.editing": "Редактирование",

  // Field editor object
  "component.field-editor-object.edit": "Изменить",
  "component.field-editor-object.name": "Имя",
  "component.field-editor-object.type": "Тип",
  "component.field-editor-object.description": "Описание",
  "component.field-editor-object.label": "Ярлык",
  "component.field-editor-object.parameters": "Параметры",
  "component.field-editor-object.readOnly": "Только чтение",
  "component.field-editor-object.filter": "Фильтр",
  "component.field-editor-object.complexAddition": "Комплексное добавление",
  "component.field-editor-object.groupFields": "Группировать поля",
  "component.field-editor-object.complexAdditionFormBuilder":
    "Конструктор формы комплексного добавления",
  "component.field-editor-object.conditions": "Условия",
  "component.field-editor-object.sorting": "Сортировка",
  "component.field-editor-object.pkeyConstraint": "Ограничение pk",

  // Field editor list
  "component.field-editor-list.edit": "Изменить",
  "component.field-editor-list.name": "Имя",
  "component.field-editor-list.type": "Тип",
  "component.field-editor-list.description": "Описание",
  "component.field-editor-list.label": "Ярлык",
  "component.field-editor-list.parameters": "Параметры",
  "component.field-editor-list.readOnly": "Только чтение",
  "component.field-editor-list.filter": "Фильтр",
  "component.field-editor-list.complexAddition": "Комплексное добавление",
  "component.field-editor-list.viewerFormBuilder":
    "Конструктор формы просмотра",
  "component.field-editor-list.complexAdditionFormBuilder":
    "Конструктор формы комплексного добавления",
  "component.field-editor-list.conditions": "Условия",
  "component.field-editor-list.sorting": "Сортировка",

  // User creator
  "component.user-creator.add": "Добавить",
  "component.user-creator.user-name": "Имя пользователя",
  "component.user-creator.enter-name": "Введите имя",
  "component.user-creator.invalidName": "Недопустимое имя",

  // Group creator
  "component.group-creator.add": "Добавить",
  "component.group-creator.name": "Имя",
  "component.group-creator.enterName": "Введите имя",
  "component.group-creator.invalidName": "Недопустимое имя",

  // Table editor
  "component.table-editor.add": "Добавить",
  "component.table-editor.edit": "Изменить",
  "component.table-editor.delete": "Удалить",
  "component.table-editor.requiredFieldsValidationMessage": "Обязательное поле не заполнено",
  "component.table-editor.requiredFieldsValidationDescription": "Заполните обязательные поля",

  // table/viewer
  "component.table-viewer.total": "Всего элементов",
  "component.table-viewer.sort": "Сортировать",
  "component.table-viewer.noSort": "Без сортировки",
  "component.table-viewer.ascending": "По возрастанию",
  "component.table-viewer.ascendingEmptyBeginning":
    "По возрастанию (пустые в начале)",
  "component.table-viewer.ascendingEmptyEnd": "По возрастанию (пустые в конце)",
  "component.table-viewer.descending": "По убыванию",
  "component.table-viewer.descendingEmptyBeginning":
    "По убыванию (пустые в начале)",
  "component.table-viewer.descendingEmptyEnd": "По убыванию (пустые в конце)",
  "component.table-viewer.filter": "Фильтровать",
  "component.table-viewer.tune": "Настроить",
  "component.table-viewer.clearFilter": "Очистить фильтр",
  "component.table-viewer.clearAll": "Очистить все",

  // table-output-settings
  "component.table-output-settings.outputFormat": "Формат вывода",

  // onboarding-editor
  "component.onboarding-editor.name": "Имя",
  "component.onboarding-editor.label": "Ярлык",
  "component.onboarding-editor.description": "Описание",
  "component.onboarding-editor.attributes": "Атрибуты",
  "component.onboarding-editor.actions": "Действия",
  "component.onboarding-editor.edit": "Изменить",
  "component.onboarding-editor.delete": "Удалить",
  "component.onboarding-editor.enabled": "Включено",
  "component.onboarding-editor.disabled": "Выключено",
  "component.onboarding-editor.tour": "Тур",
  "component.onboarding-editor.add": "Добавить",
  "component.onboarding-editor.invalidName": "Недопустимое имя",
  "component.onboarding-editor.totalItems": "Всего {i} элементов",

  // table/conditions
  "component.table-conditions.noVar": "Нет переменной",
  "component.table-conditions.wrongVarType":
    "Тип переменной не соответствует типу поля",
  "component.table-conditions.wrongConstType":
    "Тип константы не соответствует типу поля",
  "component.table-conditions.leftBorder": "Левая граница",
  "component.table-conditions.rightBorder": "Правая граница",

  // onboarding-step-editor
  "component.onboarding-step-editor.title": "Заголовок",
  "component.onboarding-step-editor.comment": "Комментарий",
  "component.onboarding-step-editor.add": "Добавить",
  "component.onboarding-step-editor.edit": "Изменить",

  // base-modal-window
  "component.base-modal-window.cancel": "Отмена",

  // table-args
  "component.table-args.totalItems": "Всего {i} элементов",

  // complex-inserter
  "component.complex-inserter.add": "Комплексное добавление",
  "component.complex-inserter.edit": "Комплексное редактирование",
  "component.complex-inserter.delete": "Комплексное удаление",

  // data input jsonb metadata
  "component.data-input-jsonb-metadata.geolocation": "Геолокация",
  "component.data-input-jsonb-metadata.user": "Пользователь",
  "component.data-input-jsonb-metadata.time": "Время",

  // dir editor
  "component.dir-editor.enterName": "Введите имя",

  // fields-group-editor
  "component.modal.fields-group-editor.add": "Добавить",
  "component.modal.fields-group-editor.edit": "Редактировать",
  "component.modal.fields-group-editor.enabled": "Включено",
  "component.modal.fields-group-editor.disabled": "Выключено",
  "component.modal.fields-group-editor.name": "Имя",
  "component.modal.fields-group-editor.example": "Пример: абв",
  "component.modal.fields-group-editor.label": "Ярлык",

  // grouping
  "component.menus.grouping.add": "Добавить",
  "component.menus.grouping.edit": "Редактировать",
  "component.menus.grouping.delete": "Удалить",

  ...fileManager,
  ...logger,
  ...loggerViewerLocaleRu
};
